import React from "react";

import "../styles/Projects.css";

function Projects() {
  return (
    <div className="wrapper-projects">
      <h1 className="project-title title-projects">Mes projets</h1>
      <div className="projects-presentation">
        <p>
          Voici quelques-uns des projets sur lesquels j'ai travaillé.Du fait de
          l'obtention récente de mon diplome, la liste est pour l'instant mince
          , mais je travaille sur des projets qui seront ajoutés avec le temps.
          Pour plus de détails, n'hésitez pas à me contacter.
        </p>
      </div>
      <section id="projects">
        <div className="project-card">
          <div className="image-container">
            <img src="../images/fiftyone.png" alt="Project Thumbnail" />
          </div>
          <h2 className="project-title">Fifty-one Enghien</h2>
          <p className="project-description">
            Dans le cadre de mon projet de fin d'études , j'ai réalisé le site
            pour le service club d'Enghien. Le site a été développé en symfony.
          </p>
          <div className="button">
            <a
              href="https://fiftyone-enghien.com"
              target="_blank"
              className="button-link"
            >
              Visiter le site
            </a>
          </div>
        </div>
        <div className="project-card">
          <div className="image-container">
            <img src="../images/arpgan.png" alt="Project Thumbnail" />
          </div>
          <h2 className="project-title">Bruxelles Propreté</h2>
          <p className="project-description">
            Durant mon stage , j'ai participé à la création du site de Bruxelles
            Propreté , notemment le back-end. Il est développé avec le CMS
            Drupal.
          </p>
          <div className="button">
            <a href="https://www.arp-gan.be/fr"target="_blank"className="button-link">
              Visiter le site
            </a>
          </div>
        </div>
        <div className="project-card">
          <div className="image-container">
            <img src="../images/logo-pneus.png" alt="Project Thumbnail" />
          </div>
          <h2 className="project-title">Garage Bosmans</h2>
          <p className="project-description">
            Mon premier projet professionnel , j'ai réalisé le site pour le garage Bosmans. Le site a été développé en next.Js.
          </p>
          <div className="button">
            <a href="https://bosmans.vercel.app"target="_blank"className="button-link">
              Visiter le site
            </a>
          </div>
        </div>
      </section>

      <p className="projects-presentation">Et d'autres à venir bientôt...</p>
    </div>
  );
}

export default Projects;
