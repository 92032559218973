import React from 'react';

function Education() {
  return (
    <div>
      <h1>About Me</h1>
      <p>This is a paragraph about me...</p>
    </div>
  );
}

export default Education;
