import React, { useState, useEffect } from "react";
import "../styles/AboutMe.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode,
  faDesktop,
  faMobileAlt,
  faNetworkWired,
  faLanguage,
} from "@fortawesome/free-solid-svg-icons";

const words = ["Developper", "Dreamer", "Maker", "Gamer"]; // Liste de mots

const AboutMe = () => {
  const [currentWord, setCurrentWord] = useState("");
  const [activeWordIndex, setActiveWordIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPause, setIsPause] = useState(false); // Nouvelle variable pour gérer la pause

  useEffect(() => {
    let timer;

    // Si en pause, attendez un peu plus longtemps avant d'effacer
    if (isPause) {
      timer = setTimeout(() => {
        setIsPause(false);
        setIsDeleting(true);
      }, 2000); // Délai plus long une fois le mot entièrement écrit
    } else if (isDeleting) {
      timer = setTimeout(() => {
        setCurrentWord((prev) => prev.slice(0, prev.length - 1));
        if (currentWord.length === 0) {
          setIsDeleting(false);
          setActiveWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        }
      }, 100); // Vitesse d'effacement
    } else {
      timer = setTimeout(() => {
        setCurrentWord(words[activeWordIndex].slice(0, currentWord.length + 1));
        if (currentWord.length === words[activeWordIndex].length - 1) {
          setIsPause(true); // Commence la pause une fois le mot entièrement affiché
        }
      }, 150); // Vitesse de frappe
    }

    return () => clearTimeout(timer);
  }, [currentWord, isDeleting, activeWordIndex, isPause]);

  return (
    <div>
      <div className="hero-section" id="hero-section">
        <h1 className="typing">{currentWord}</h1>
      </div>
      <div className="presentation-section" id="presentation-section">
        <div className="presentation-wrapper">
          <h2 class="about-title">À propos de moi</h2>

          <p>
            Je m'appelle <span className="highlight">Gauthier Minor</span>, j'ai
            28 ans, et je viens tout juste d'être diplômé d'un brevet d'études
            supérieures spécialisé dans le{" "}
            <span className="highlight">développement web</span>. Au cours de ma
            formation, j'ai acquis une maîtrise approfondie des différentes
            techniques nécessaires pour créer des sites web{" "}
            <span className="highlight">dynamiques</span>, couvrant à la fois le{" "}
            <span className="highlight">développement front-end</span> et{" "}
            <span className="highlight">back-end</span>. Passionné par la
            technologie et la création numérique, je suis constamment à la
            recherche de nouveaux défis et projets pour mettre en pratique mes
            compétences et continuer à apprendre.
          </p>

      <h2 className="about-title">Mes Qualités</h2>
          <div className="skills-section">
      <div className="skills">

      <div className="skill">
        <div className="skill-icon">
          <FontAwesomeIcon icon={faCode} size="2x" />
        </div>
          <h4 class="title-skill" style={{ color: '#8ba8ce' }}>Front-end</h4>
        <div className="skill-content">
          <p>Création d'interfaces utilisateur attrayantes et interactives.</p>
        </div>
      </div>
      <div className="skill">
        <div className="skill-icon">
          <FontAwesomeIcon icon={faNetworkWired} size="2x" />
        </div>
          <h4 class="title-skill" style={{ color: '#8ba8ce' }}>Back-end</h4>
        <div className="skill-content">
          <p>Développement de logique serveur et gestion de base de données.</p>
        </div>
      </div>
      <div className="skill">
        <div className="skill-icon">
          <FontAwesomeIcon icon={faDesktop} size="2x" />
        </div>
          <h4 class="title-skill" style={{ color: '#8ba8ce' }}>Responsive Design</h4>
        <div className="skill-content">
          <p>Adaptation des sites pour une visualisation optimale sur tout appareil.</p>
        </div>
      </div>
      <div className="skill">
        <div className="skill-icon">
          <FontAwesomeIcon icon={faMobileAlt} size="2x" />
        </div>
          <h4 class="title-skill" style={{ color: '#8ba8ce' }}>Web Dynamique</h4>
        <div className="skill-content">
          <p>Création de sites web dynamiques avec des interactions riches.</p>
        </div>
      </div>
      <div className="skill">
        <div className="skill-icon">
          <FontAwesomeIcon icon={faLanguage} size="2x" />
        </div>
          <h4 class="title-skill" style={{ color: '#8ba8ce' }}>Anglais</h4>
        <div className="skill-content">
          <p>Capacité à communiquer efficacement dans un environnement anglophone.</p>
        </div>
      </div>
      </div>
    </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
