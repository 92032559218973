import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navigation from './Components/Navigation';
import './styles/Global.css';
import { AnimatePresence } from 'framer-motion';

// Import your component files here
import AboutMe from './Pages/AboutMe';
import Projects from './Pages/Projects';
import Skills from './Pages/Skills';
import Apropos from './Pages/Apropos';
import Education from './Pages/Education';
import Contact from './Pages/Contact';

const App = () => {
  return (
    <Router>
      <div style={{ display: 'flex' }}>
        <Navigation/>
        <main className="main-content" style={{ flex: 1 }}>
          <RouteContainer />
        </main>
      </div>
    </Router>
  );
}

// Helper component to use useLocation hook
const RouteContainer = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" exact element={<AboutMe />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/apropos" element={<Apropos />} />
        <Route path="/education" element={<Education />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </AnimatePresence>
  );
}

export default App;
