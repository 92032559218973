import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin  } from '@fortawesome/free-brands-svg-icons';
import { faHome, faProjectDiagram, faSpinner, faBriefcase, faEnvelope,faBars } from '@fortawesome/free-solid-svg-icons';
import '../styles/Navigation.css'; // Assurez-vous que le chemin est correct

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Close the menu when a link is clicked
  const handleLinkClick = () => {
    if (isOpen) toggleMenu();
    scrollToTop(); // Ajoutez cette ligne
  };

  // Function to scroll to top of page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // Close the menu by clicking outside of it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      const nav = document.querySelector('.nav');
      if (nav && !nav.contains(event.target) && isOpen) {
        toggleMenu();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [isOpen]); // Only re-run if isOpen changes

  return (
    <React.Fragment>
      <div className="burger" onClick={toggleMenu}>
        <FontAwesomeIcon icon={faBars} /> {/* Burger Icon */}
      </div>
      <nav className={`nav ${isOpen ? 'open' : ''}`}>
        <div className={`menu ${isOpen ? 'open' : ''}`}>
          {/* Existing navigation structure */}
          {/* Rest of your component */}
          <div className="profile-section">
            <img src="/images/profiles.jpg" alt="Your Name" className="profile-photo"/>
            <div className="social-links">
              <a href="https://github.com/Slipaclous" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faGithub} />
              </a>
              <a href="https://www.linkedin.com/in/gauthier-minor-a0a4a229b/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </div>
          </div>
          <ul style={{ listStyleType: 'none', padding: '20px' }}>
            <li><Link to="/" onClick={handleLinkClick}><FontAwesomeIcon icon={faHome} className="icon"/> Home</Link></li>
            <li><Link to="/skills" onClick={handleLinkClick}><FontAwesomeIcon icon={faSpinner} className="icon"/> Compétences</Link></li>
            <li><Link to="/projects" onClick={handleLinkClick}><FontAwesomeIcon icon={faProjectDiagram} className="icon"/> Mes projets</Link></li>
            <li><Link to="/apropos" onClick={handleLinkClick}><FontAwesomeIcon icon={faBriefcase} className="icon"/> A propos</Link></li>
            <li><Link to="/contact" onClick={handleLinkClick}><FontAwesomeIcon icon={faEnvelope} className="icon"/> Contact</Link></li>
          </ul>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Navigation;
