import React from 'react';

import '../styles/about.css';

function Apropos() {
  return (
    <div class="progress">
      <h1>Work in progress</h1>
      <p>Cette page est en cours de construction et sera bientôt disponible , un peu de patience ...</p>
    </div>
  );
}

export default Apropos;
