

import React, { useState, useEffect } from 'react';
import logoSymfony from '../logos/symfony.svg';
import logoReact from '../logos/react.svg';
import logoJavascript from '../logos/javascript.svg';
import logoHtml from '../logos/html.svg';
import logoCss from '../logos/css.svg';
import logoPhp from '../logos/php.svg';
import logoDrupal from '../logos/drupal.svg';
import lofoSass from '../logos/sass.svg';
import logoBootstrap from '../logos/bootstrap.svg';
import '../styles/Skills.css';

const Skill = ({ logo, title, description }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 }
    );

    const targetElement = document.querySelector(`#${title}`);

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, [title]);

  return (
    <div id={title} className={`skill-container ${isVisible ? 'visible' : ''}`}>
      <div className="skill-section">
        <div className="description">
        <img src={logo} alt={title} className="skill-logo" />
          <h2 className="skill-title">{title}</h2>
          <p className="skill-description">{description}</p>
        </div>
      </div>
    </div>
  );
};

const Skills = () => {
  return (
    <div className="skills-container">
      <Skill
        logo={logoHtml}
        title="html"
        description="Un langage de balisage pour la création de pages web"
      />
      <Skill
        logo={logoCss}
        title="css"
        description="Un langage de style utilisé pour décrire la présentation d'un document écrit en HTML"
      />
      <Skill
        logo={logoPhp}
        title="php"
        description="Un langage de programmation principalement utilisé pour le développement web"
      />
      <Skill
        logo={logoJavascript}
        title="javascript"
        description="Un langage de programmation de scripts principalement utilisé dans les pages web interactives"
      />
      <Skill
        logo={logoSymfony}
        title="symfony"
        description="Un framework PHP pour les applications web"
      />
      <Skill
        logo={logoReact}
        title="react"
        description="Une bibliothèque JavaScript pour construire des interfaces utilisateur"
      />
      <Skill
        logo={logoDrupal}
        title="drupal"
        description="Un système de gestion de contenu open-source"
      />
      <Skill
        logo={lofoSass}
        title="sass"
        description="Un préprocesseur CSS"
      />
      <Skill
        logo={logoBootstrap}
        title="bootstrap"
        description="Un framework CSS"
      />
      {/* Ajoutez d'autres sections pour les autres langages */}
    </div>
  );
};

export default Skills;
