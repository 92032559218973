import React, { useRef, useState } from 'react';
import '../styles/Contact.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import emailjs from 'emailjs-com';


//import des icones de localisation et e-mail
import { faMapMarkerAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";

function Contact() {
  const [token, setToken] = useState(''); // Store the ReCAPTCHA token
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const recaptchaRef = useRef();




  const handleToken = (token) => {
    console.log("Token received:", token); // Add this line to check if the token is being received
    setToken(token);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_hsnjwxq', 'template_w9td2do', e.target, 'K5Twd1LE__X0iu0pO')
      .then((result) => {
        console.log(result.text);
        setFormData({ name: '', email: '', message: '' }); // Réinitialiser les champs du formulaire
        setSuccessMessage('Votre message a été envoyé avec succès !'); // Afficher la bannière de succès
        setTimeout(() => {
          setSuccessMessage(''); // Cacher la bannière de succès après 3 secondes
        }, 3000);
      }, (error) => {
        console.log(error.text);
      });
  };
  
 
  
  
  
  return (
    <div className="container-contact">
      <div className="wrapper-contact">

        <h2 className='contact-title'>Contact</h2>

        <p className='contact-description'>
          Je suis toujours à la recherche de nouveaux projets et opportunités. Si vous avez des questions, des idées, ou juste envie de me contacter, je suis disponible pour vous aider. Vous pouvez facilement me joindre en utilisant le formulaire de contact ou en m'envoyant un mail à l'adresse que vous retrouverez ci-dessous. <strong class="highlight">Votre avis compte beaucoup</strong> et je serais ravi de vous répondre. Ouvrons le dialogue et explorons ensemble les possibilités.
        </p>

        <div className="form-map">

        <div className="card-map">
          <div className="location info">
            <div className="icon-circle">
              <FontAwesomeIcon icon={faMapMarkerAlt} class='icon-contact' />
            </div>
            <div className="info-contact">
              <h4 className="info-title">Localisation:</h4>
            <p>Enghien, Belgique</p>
            </div>
          </div>
          <div className="email info">
          <div className="icon-circle">
            <FontAwesomeIcon icon={faEnvelope} class="icon-contact" />
          </div>
          <div className="info-contact">
          <h4 className="info-title">Email:</h4>
            <p>gauthier.minor@gmail.com </p>
          </div>
          </div>
          <div className="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2518.558617153946!2d4.030258315699568!3d50.70853597950523!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3d9f7d6b6b4a3%3A0x2e1e2c5e0a4e7f5d!2sEnghien!5e0!3m2!1sfr!2sbe!4v1617751981745!5m2!1sfr!2sbe" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>

          </div>
        </div>

        <div className="form-contact">
          <form onSubmit={handleOnSubmit} method="POST">
            <div className="form-infos">

            <div className="form-group">
              <label htmlFor="name">Votre Nom</label>
              <input
            type="text"
            id="name"
            name="name"
            required
            value={formData.name}
            onChange={handleChange}
          />
            </div>
            <div className="form-group">
              <label htmlFor="email">Votre Email</label>
              <input
            type="email"
            id="email"
            name="email"
            required
            value={formData.email}
            onChange={handleChange}
          /> {/* Updated name attribute */}
            </div>

            </div>
            <div className=" message">
              <label for="message">Message</label>
              <textarea
            id="message"
            name="message"
            required
            value={formData.message}
            onChange={handleChange}
          ></textarea>
            </div>
            <div className="button-div">
            <ReCAPTCHA
  ref={recaptchaRef}
  sitekey={process.env.REACT_APP_SITE_KEY}
  size="invisible"
  onChange={handleToken}
/>
              
            <button class="button-submit" type="submit" className="button-contact">Envoyer</button>
            </div>
          </form>
          {successMessage && <div className="success-message">{successMessage}</div>}
      </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;